<template>
  <div v-loading="loading">
    <ui-card :heading="$tc('links.links', 2)" class="mb-8">
      <ui-form
        @submit="handleSubmit"
        :submitText="$t('actions.save')"
        @cancel="handleCancel"
      >
        <div style="display: flex; gap: 4rem">
          <div style="flex: 1">
            <ui-form-field :label="$t('fields.name')" rules="required">
              <el-input type="text" v-model="registrationLink.name"></el-input>
            </ui-form-field>

            <ui-form-field :label="$tc('models.building', 1)" rules="required">
              <el-select
                v-model="registrationLink.assignedBuildingId"
                placeholder="Select building"
                v-if="departments"
              >
                <el-option
                  v-for="item in buildings"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </ui-form-field>

            <ui-form-field :label="$tc('models.department', 1)">
              <el-select
                v-model="registrationLink.assignedDepartmentId"
                placeholder="Select department"
                v-if="departments"
                :disabled="!registrationLink.assignedBuildingId"
                clearable
              >
                <el-option
                  v-for="item in departments.filter(
                    (d) => d.buildingId == registrationLink.assignedBuildingId
                  )"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </ui-form-field>

            <ui-form-field :label="$t('links.expirationDate')">
              <el-date-picker
                v-model="registrationLink.expires"
                type="date"
                :placeholder="$t('fields.date')"
              />
            </ui-form-field>
          </div>
          <div style="flex: 1">
            <label
              v-if="registrationLink && registrationLink.uri"
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-sm
                font-bold
                mb-2
              "
              style="margin-left: 16px"
              >{{ $t("links.qrCode") }}</label
            >
            <span>
              <canvas
                id="qrc-canvas"
                style="width: 260px !important; height: 260px !important"
                width="260px"
                height="260px"
              ></canvas>
            </span>
            <p
              class="mb-2"
              style="margin-left: 16px"
              v-if="registrationLink && registrationLink.uri"
            >
              {{ $t("links.download_qr") }}
            </p>

            <div class="mt-8"></div>

            <div class="mb-2" style="margin-left: 16px">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-sm
                  font-bold
                "
                v-if="qrCodeUrl"
              >
                URL
              </label>

              <a :href="qrCodeUrl">{{ qrCodeUrl }}</a>
            </div>
          </div>
        </div>

        <ui-form-field
          :label="$tc('models.permissions')"
          v-if="companyPermissions"
        >
          <ui-list
            :disableXScroll="true"
            :headings="[
              $tc('models.scope'),
              $tc('models.permission'),
              '',
              '',
              $tc('models.allow'),
              // ''
            ]"
            :items="companyPermissions"
          >
            <template v-slot:default="props">
              <ui-list-data type="tag" :text="props.item.scope" />
              <ui-list-data type="tag" :text="props.item.permission" />
              <ui-list-data />
              <ui-list-data />
              <ui-list-data
                ><el-checkbox v-model="props.item.hasPermission"
              /></ui-list-data>
            </template>
          </ui-list>
        </ui-form-field>
      </ui-form>
    </ui-card>
  </div>
</template>

<script>
import UiCard from "@/components/ui/UiCard";
import UiForm from "@/components/ui/UiForm";
import UiFormField from "@/components/ui/UiFormField";
import UiList from "@/components/ui/UiList";
import UiListData from "@/components/ui/UiListData";
import UiButton from "@/components/ui/UiButton";
import QRCode from "qrcode";

import {
  createRegistrationLink,
  updateRegistrationLink,
  getActiveCompanyPermissions,
  getRegistrationLinkByReference,
  getCompanyDepartments,
  getCompanyBuildings,
  getCompany,
} from "../api";

export default {
  components: {
    UiCard,
    UiForm,
    UiFormField,
    UiList,
    UiListData,
  },

  data() {
    return {
      loading: false,
      showDeleteModal: false,
      allPermissions: null,
      companyPermissions: null,
      expDate: null,
      qrCode: null,
      departments: null,
      company: {
        departments: null,
      },
      registrationLink: {
        name: null,
        expires: null,
        permissions: null,
        companyId: null,
        assignedDepartmentId: null,
        assignedBuildingId: null,
      },
      qrCodeUrl: null,
    };
  },

  watch: {
    registrationLink: {
      deep: true,
      handler: function (registrationLink) {
        if (registrationLink && registrationLink.uri) {
          this.qrCodeUrl = `${window.location.protocol}//${window.location.host}/${this.$route.params.lang}${this.registrationLink.uri}`;
          QRCode.toCanvas(
            document.getElementById("qrc-canvas"),
            this.qrCodeUrl,
            {
              rendererOpts: {
                quality: 2,
              },
            },
          );
        } else {
          // Clear canvas
          const canvas = document.createElement("canvas");
          canvas.id = "qrc-canvas";

          document
            .getElementById("qrc-canvas")
            .parentElement.appendChild(canvas);
          document
            .getElementById("qrc-canvas")
            .parentElement.removeChild(document.getElementById("qrc-canvas"));
        }
      },
    },
  },

  async beforeMount() {
    this.company = await getCompany(this.$route.params.id);
    this.companyPermissions = this.companyPermissions =
      await getActiveCompanyPermissions(this.company.id);
    this.companyPermissions.forEach((p) => (p.hasPermission = false));
    this.company.departments = await getCompanyDepartments(
      this.$route.params.id
    );
    this.company.buildings = await getCompanyBuildings(this.$route.params.id);
    this.departments = this.company.departments;
    this.buildings = this.company.buildings;

    if (this.$route.params.reference) {
      this.registrationLink = await getRegistrationLinkByReference(
        this.$route.params.reference
      );

      // map the available permissions for the company to the selected permissions for the registration links
      if (this.registrationLink.companyPermissions) {
        this.companyPermissions.forEach((p) => {
          p.hasPermission = this.registrationLink.companyPermissions
            .map((x) => x.companyPermissionId)
            .includes(p.companyPermissionId);
        });
      }
    }

    // breadcrumbs
    this.$store.commit("changeBreadcrumbs", [
      { name: "models.companies", link: "/app/companies/" },
      {
        name: this.company.name,
        link: "/app/companies/" + this.company.id + "/edit",
      },
      {
        name: "actions.edit",
        link: "/app/companies/" + this.company.id + "/edit",
      },
      {
        name: "Links",
        link: "/app/companies/" + this.company.id + "/edit/link",
      },
    ]);
  },

  mounted() {},

  methods: {
    async getRegistrationLinkByReference(reference) {
      if (reference) {
        this.loading = true;
        this.registrationLink = await getRegistrationLinkByReference(
          reference
        ).finally(() => (this.loading = false));
      }
    },

    async handleSubmit() {
      this.loading = true;

      this.registrationLink.companyId = this.company.id;
      this.registrationLink.companyPermissions = this.companyPermissions.filter(
        (p) => p.hasPermission
      );

      if (this.$route.params.reference) {
        if (this.registrationLink.assignedDepartmentId == "") {
          this.registrationLink.assignedDepartmentId = null;
        }
        await updateRegistrationLink(this.registrationLink).finally(() => {
          this.loading = false;
        });
      } else {
        await createRegistrationLink(this.registrationLink)
          .then((body) => {
            this.registrationLink = body;
            this.$router.push({
              name: "companies-link-edit",
              params: {
                reference: body.reference,
              },
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    handleCancel() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.el-button--primary {
  background-color: #f3f3f3;
  color: black;
  border-color: #f3f3f3;
  font-weight: 700;
}

.logo_form_item {
  display: inline-flex;
}

.background_form_item {
  display: inline-flex;
}

.delete_button {
  margin-left: 1em;
}
</style>
