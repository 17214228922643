var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('ui-card',{staticClass:"mb-8",attrs:{"heading":_vm.$tc('links.links', 2)}},[_c('ui-form',{attrs:{"submitText":_vm.$t('actions.save')},on:{"submit":_vm.handleSubmit,"cancel":_vm.handleCancel}},[_c('div',{staticStyle:{"display":"flex","gap":"4rem"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('ui-form-field',{attrs:{"label":_vm.$t('fields.name'),"rules":"required"}},[_c('el-input',{attrs:{"type":"text"},model:{value:(_vm.registrationLink.name),callback:function ($$v) {_vm.$set(_vm.registrationLink, "name", $$v)},expression:"registrationLink.name"}})],1),_c('ui-form-field',{attrs:{"label":_vm.$tc('models.building', 1),"rules":"required"}},[(_vm.departments)?_c('el-select',{attrs:{"placeholder":"Select building"},model:{value:(_vm.registrationLink.assignedBuildingId),callback:function ($$v) {_vm.$set(_vm.registrationLink, "assignedBuildingId", $$v)},expression:"registrationLink.assignedBuildingId"}},_vm._l((_vm.buildings),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1):_vm._e()],1),_c('ui-form-field',{attrs:{"label":_vm.$tc('models.department', 1)}},[(_vm.departments)?_c('el-select',{attrs:{"placeholder":"Select department","disabled":!_vm.registrationLink.assignedBuildingId,"clearable":""},model:{value:(_vm.registrationLink.assignedDepartmentId),callback:function ($$v) {_vm.$set(_vm.registrationLink, "assignedDepartmentId", $$v)},expression:"registrationLink.assignedDepartmentId"}},_vm._l((_vm.departments.filter(
                  (d) => d.buildingId == _vm.registrationLink.assignedBuildingId
                )),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1):_vm._e()],1),_c('ui-form-field',{attrs:{"label":_vm.$t('links.expirationDate')}},[_c('el-date-picker',{attrs:{"type":"date","placeholder":_vm.$t('fields.date')},model:{value:(_vm.registrationLink.expires),callback:function ($$v) {_vm.$set(_vm.registrationLink, "expires", $$v)},expression:"registrationLink.expires"}})],1)],1),_c('div',{staticStyle:{"flex":"1"}},[(_vm.registrationLink && _vm.registrationLink.uri)?_c('label',{staticClass:"block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2",staticStyle:{"margin-left":"16px"}},[_vm._v(_vm._s(_vm.$t("links.qrCode")))]):_vm._e(),_c('span',[_c('canvas',{staticStyle:{"width":"260px !important","height":"260px !important"},attrs:{"id":"qrc-canvas","width":"260px","height":"260px"}})]),(_vm.registrationLink && _vm.registrationLink.uri)?_c('p',{staticClass:"mb-2",staticStyle:{"margin-left":"16px"}},[_vm._v(" "+_vm._s(_vm.$t("links.download_qr"))+" ")]):_vm._e(),_c('div',{staticClass:"mt-8"}),_c('div',{staticClass:"mb-2",staticStyle:{"margin-left":"16px"}},[(_vm.qrCodeUrl)?_c('label',{staticClass:"block uppercase tracking-wide text-gray-700 text-sm font-bold"},[_vm._v(" URL ")]):_vm._e(),_c('a',{attrs:{"href":_vm.qrCodeUrl}},[_vm._v(_vm._s(_vm.qrCodeUrl))])])])]),(_vm.companyPermissions)?_c('ui-form-field',{attrs:{"label":_vm.$tc('models.permissions')}},[_c('ui-list',{attrs:{"disableXScroll":true,"headings":[
            _vm.$tc('models.scope'),
            _vm.$tc('models.permission'),
            '',
            '',
            _vm.$tc('models.allow'),
            // ''
          ],"items":_vm.companyPermissions},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ui-list-data',{attrs:{"type":"tag","text":props.item.scope}}),_c('ui-list-data',{attrs:{"type":"tag","text":props.item.permission}}),_c('ui-list-data'),_c('ui-list-data'),_c('ui-list-data',[_c('el-checkbox',{model:{value:(props.item.hasPermission),callback:function ($$v) {_vm.$set(props.item, "hasPermission", $$v)},expression:"props.item.hasPermission"}})],1)]}}],null,false,1593522631)})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }